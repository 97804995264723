.container {
  max-width: 1024px;
  margin: 0 auto;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  // position: relative;
  background: #282c34;
  color: white;
}

.container::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #282c34;
  z-index: -1;
}
