.wrapper {
  width: 80%;
  margin: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  @media (max-width: 768px) {
    justify-content: center;
  }
}

.p1 {
  font-size: 16px;
  color: white;
}

.nav {
  text-decoration: none;
  color: white;
  font-size: 1rem;
  border: 1px solid white;
  padding: 0.3rem 0.5rem;
  border-radius: 2px;
  border: none;
  background: #e88401;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
  height: auto;
  align-self: center;
  &:hover {
    background: #d47a0567;
    // padding: 1rem;
  }
}

.ps {
  @extend .p1;
  text-align: center;
  text-shadow: 0px 20px 20px #ec4b06;
  color: #d47a05;
}

.button {
  display: flex;
  gap: 1rem;
}
