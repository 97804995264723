.btnDel {
  position: absolute;
  top: 0;
  left: 90px;
  z-index: 2;
  color: red;
  &:hover {
    scale: 1.7;
    cursor: pointer;
  }
}

.productItem {
  width: 300px; // Или 300px, если нужно
  height: auto; // Высота подстраивается под содержимое
  border: 2px solid #e88401;
  margin-top: 15px;
  padding: 15px;
  display: flex;
  flex-direction: row; // Элементы располагаются по горизонтали
  justify-content: space-between;
  align-items: center; // Выравнивание по вертикали
  color: white;
  box-sizing: border-box; // Учитываем padding в ширине

  &__content {
    font-size: 16px;
    margin: 0;
    flex: 1; // Контент занимает оставшееся место
    display: flex;
    flex-direction: column; // Внутри блока располагаем элементы вертикально
    gap: 0.5rem;
  }

  // &__btns {
  //   padding: 0.5em;
  //   background-color: #e88401;
  //   color: white;
  //   border: none;
  //   cursor: pointer;
  //   text-align: center;
  //   height: fit-content; // Подстраиваем высоту под текст
  //   white-space: nowrap; // Текст не переносится
  //   flex-shrink: 0; // Запрещаем сжатие кнопки

  //   &:hover {
  //     background-color: #d17400;
  //   }
  // }

  &__imageGallery {
    display: flex;
    overflow-x: auto; // Прокрутка для галереи
    gap: 5px;
    padding: 5px 0;
    max-width: 100px; // Ограничение ширины галереи
    white-space: nowrap; // Убираем переносы
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    flex-shrink: 0; // Изображения не сжимаются
  }
}

.nameItemAmount {
  display: flex;
  align-items: center;
}

.p {
  margin-left: 30px;
}
