.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // display: none;
  opacity: 0;
  transition: all 0.8s;

  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.modal.active {
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    // align-items: start;
    display: block;
  }
}

.modalContent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem; //включаем паддинг и есть проблема с выравниванием
  border-radius: 5px;
  background-color: black;
  max-width: 1200px;
  object-fit: cover;
  z-index: 3;

  @media (max-width: 600px) {
    border-radius: 0;
    background-color: rgb(20, 70, 53);
    color: white;
  }
}

// @media (max-width: 1199px) {
//   .modal.active {
//     // margin-top: 2rem;
//     // align-items: flex-start;
//   }
// }
