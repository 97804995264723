.myBtn {
  padding: 5px;
  margin: 5px;
  color: white;
  font-size: 14px;
  // background-color: transparent;
  background-color: #e88401;
  border: 1px solid #e88401;
  cursor: pointer;
  &:hover {
    background-color: #d47a0567;
  }
}
