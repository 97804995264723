.panel {
  position: relative;
  top: 20px;
  // min-height: 80%;
  //   left: 20%;
  padding: 20px;
  background: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  //   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: none;
  //   transition: opacity 0.3s ease-in-out;
  color: black;
}

.visible {
  display: block;
  opacity: 1;
}

.hidden {
  //   opacity: 0;
  display: none;
}

.imgSrc {
  display: flex;
  flex-direction: column;
  align-items: center;
}
