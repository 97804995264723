.galleryImageServer {
  display: flex;
  flex-wrap: wrap;
}

.btnDel {
  position: absolute;
  top: 5px;
  left: 90px;
  z-index: 2;
  color: red;
  &:hover {
    scale: 1.7;
    cursor: pointer;
  }
}
