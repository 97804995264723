.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1.5rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.redpre,
.red {
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background-color: #f44336;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d32f2f;
  }
}

.blue {
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background-color: #2196f3;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1976d2;
  }
}

.text {
  font-size: 1rem;
  color: #333;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
}

input[type="checkbox"] {
  transform: scale(1.2);
  accent-color: #f44336;
  cursor: pointer;
}
