.wrapper {
  position: relative;
}
.buttonSearch {
  background-color: #dedfe3;
  color: black;
  padding: 6px 20px; /* Внутренние отступы */
  font-size: 12px; /* Размер шрифта */
  font-weight: bold; /* Жирный шрифт */
  border: none; /* Убираем границу */
  border-radius: 8px; /* Скругленные углы */
  width: 150px;
  cursor: pointer; /* Курсор при наведении */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Плавные переходы при наведении */
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 10px;
    padding: 4px 10px;
  }
  &:hover {
    background-color: #81839f63; /* Более темный оранжевый при наведении */
    transform: translateY(-2px); /* Легкое поднятие кнопки при наведении */
  }

  &:active {
    background-color: #f5f5fc3d; /* Еще более темный оранжевый при клике */
    transform: translateY(0); /* Возврат в исходное положение при клике */
  }
}
.searchList {
  position: absolute;
  // top: 100%; /* Располагаем блок с результатами ниже поля ввода */
  // right: 0;
  // z-index: 2;
  width: 400px; /* Делаем блок с результатами шириной с поле ввода */
  background-color: white; /* Белый фон для результатов */
  border: 1px solid #ccc; /* Граница для блока с результатами */
  z-index: 1000; /* Устанавливаем высокий z-index, чтобы блок с результатами отображался поверх других элементов */
  max-height: 80vh; /* Ограничиваем максимальную высоту блока с результатами */
  overflow-y: auto; /* Добавляем прокрутку, если результаты не помещаются */
  color: black;
  // @media (max-width: 960px) {
  //   width: 100vw; /* Делаем блок шириной с окно просмотра */
  //   left: 50%; /* Центрируем блок по горизонтали */
  //   transform: translateX(-50%); /* Сдвигаем блок влево на 50% его ширины */
  // }
}

.searchItem {
  height: 20px;
  padding: 5px; /* Для большего кликабельного пространства */
  transition: background-color 0.3s ease; /* Плавный переход цвета */
  cursor: pointer; /* Указатель курсора для интерактивного элемента */

  &:hover {
    background-color: #e0e0e0; /* Более тёмный серый фон */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Лёгкая тень */
    transform: scale(1.02); /* Лёгкое увеличение */
  }
}
