.part {
  display: flex;
  flex-direction: column;
  max-width: 700px; /* Ограничиваем максимальную ширину контейнера */
  align-items: center;
  // width: 100%; /* Контейнер занимает всю ширину */
  margin: 0 auto; /* Центрируем .part */
}
.form {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; /* Позволяем элементам переноситься на новую строку */
  justify-content: flex-start; /* Выровнять содержимое сверху */
  align-items: flex-start; /* Все элементы прижимаются к левой стороне */
  gap: 5px; /* Расстояние между элементами */
  min-height: 500px; /* Минимальная высота формы */
  max-width: 100%; /* Ограничиваем ширину формы */
  box-sizing: border-box; /* Учитываем padding в размерах формы */
}

// .form {
//   margin-top: 30px;
//   display: flex;
//   flex-direction: column;
//   flex-wrap: wrap; /* Позволяем элементам переноситься на новую строку */
//   justify-content: space-between; /* Равномерно распределяем элементы */
//   align-items: flex-start; /* Элементы остаются выровненными по верху */
//   gap: 5px; /* Расстояние между элементами */
//   min-height: 500px; /* Минимальная высота формы */
//   max-width: 100%; /* Ограничиваем ширину формы */
//   // padding: 3px;
//   box-sizing: border-box; /* Учитываем padding в размерах формы */
// }

.form input,
.form select,
.form button {
  flex: 1 1 calc(50% - 20px); /* Каждый элемент занимает 50% ширины, с учетом gap */
  min-width: 300px; /* Минимальная ширина для элементов */
  margin: 0;
  padding: 0px;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start !important;
  align-self: start;
  margin-left: 0; /* Убедитесь, что нет отступов слева */
  padding-left: 0; /* Убедитесь, что нет внутренних отступов слева */
}

.inputContainer {
  position: relative;
  // width: 100%;

  input {
    width: calc(100% - 3rem); // Вычитаем 3rem от общей ширины контейнера
    padding-right: 2rem; // Отступ для комфортного ввода
    box-sizing: border-box; // Учитываем отступы и границы в ширине
  }

  .inputSuffix {
    position: absolute;
    right: 0.5rem; // Смещение справа
    top: 50%; // Центровка по вертикали
    transform: translateY(
      -50%
    ); // Смещение для идеальной вертикальной центрировки
    color: #6b7280; // Серый цвет (эквивалент text-gray-500)
    font-size: 0.875rem;
    pointer-events: none; // Убираем возможность клика
  }
}

// * {
//   box-sizing: border-box;
//   margin: 0;
//   padding: 0;
// }

// body {
//   font-family: "Arial", sans-serif;
//   background-color: #f4f4f9;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   margin: 0;
// }

// .form-container {
//   background-color: #fff;
//   padding: 20px;
//   border-radius: 8px;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//   width: 100%;
//   max-width: 600px;
// }

// h2 {
//   text-align: center;
//   color: #333;
//   margin-bottom: 20px;
// }

// .product-form {
//   display: flex;
//   flex-direction: column;
// }

// .form-group {
//   margin-bottom: 15px;
// }

// label {
//   font-size: 14px;
//   color: #555;
//   margin-bottom: 5px;
// }

// input,
// textarea {
//   width: 100%;
//   padding: 10px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   font-size: 14px;
//   color: #333;
//   transition: border-color 0.3s;
// }

// input:focus,
// textarea:focus {
//   border-color: #4a90e2;
//   outline: none;
// }

// textarea {
//   resize: vertical;
//   min-height: 100px;
// }

// .error {
//   border-color: #e74c3c;
// }

// .error-message {
//   font-size: 12px;
//   color: #e74c3c;
//   margin-top: 5px;
// }

// .form-actions {
//   display: flex;
//   justify-content: center;
// }

// button {
//   background-color: #4a90e2;
//   color: #fff;
//   padding: 10px 20px;
//   border: none;
//   border-radius: 4px;
//   font-size: 16px;
//   cursor: pointer;
//   transition: background-color 0.3s;
// }

// button:hover {
//   background-color: #357ab7;
// }

// button:disabled {
//   background-color: #ccc;
//   cursor: not-allowed;
// }
